import React from 'react';
import SingleColPageLayout from './single-col-page-layout';
import MetaTags from '../components/MetaTags';

export default ({ children, pageContext }) => {
  const slug = pageContext.frontmatter?.slug || pageContext.slug;
  const { title, description, pageHeading, customCss } =
    pageContext.frontmatter;
  const wrapperCss = customCss || '';
  return (
    <SingleColPageLayout>
      <MetaTags title={title} description={description} path={slug} />
      <div className={wrapperCss}>
        {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}
        <div className="page-content"> {children}</div>
      </div>
    </SingleColPageLayout>
  );
};
