import React from 'react';
import { Helmet } from 'react-helmet';
import pathJoin from '../utils/path-join';
import siteConfig from '../../data/SiteConfig';

export default function PageMetaTags({
  path,
  title,
  description,
  socialDescription,
  image,
  publishedDate,
  twitterCardType = 'summary',
  isBlogPost = false,
  appendSiteTitleToPageTitle = true,
}) {
  const pageUrl = pathJoin([siteConfig.siteUrl, path || ''], true);
  const logoUrl = pathJoin([siteConfig.siteUrl, siteConfig.siteLogo]);
  const ogImage = image ? pathJoin([siteConfig.siteUrl, image]) : logoUrl;
  let pageTitle;
  if (title) {
    pageTitle = appendSiteTitleToPageTitle
      ? `${title} | ${siteConfig.siteTitle}`
      : title;
  } else {
    pageTitle = siteConfig.siteTitle;
  }
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteConfig.siteUrl,
      name: siteConfig.siteTitle,
      alternateName: siteConfig.siteTitleAlt || '',
    },
  ];
  if (isBlogPost) {
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      url: pageUrl,
      name: title,
      headline: pageTitle,
      image: {
        '@type': 'ImageObject',
        url: ogImage,
      },
      description,
      datePublished: publishedDate,
      author: {
        '@type': 'Person',
        name: siteConfig.userFullName,
        sameAs: [siteConfig.twitterProfileUrl],
      },
      publisher: {
        '@type': 'Organization',
        name: siteConfig.siteTitle,
        logo: {
          '@type': 'ImageObject',
          contentUrl: logoUrl,
          url: logoUrl,
        },
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': pageUrl,
      },
    });
  }
  return (
    <Helmet>
      <link rel="canonical" href={pageUrl} />
      <meta name="description" content={description} />
      <meta property="article:author" content={siteConfig.userFullName} />
      <meta name="author" content={siteConfig.userFullName} />
      <meta name="twitter:card" content={twitterCardType} />
      <meta name="twitter:domain" content="serverlessfirst.com" />
      <meta name="twitter:site" content={siteConfig.userTwitter} />
      <meta name="twitter:creator" content={siteConfig.userTwitter} />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:title" content={pageTitle} />
      <meta
        name="twitter:description"
        content={socialDescription || description}
      />
      <meta name="twitter:image" content={ogImage} />
      <meta name="image" content={ogImage} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={pageUrl} />
      <meta
        property="og:description"
        content={socialDescription || description}
      />
      {publishedDate ? (
        <meta
          property="article:published_time"
          content={new Date(publishedDate).toISOString()}
        />
      ) : null}
      <meta property="og:site_name" content={siteConfig.siteTitle} />
      <meta property="og:image" content={ogImage} />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <title>{pageTitle}</title>
    </Helmet>
  );
}
