import React from 'react';
import Layout from './layout';

const SingleColPageLayout = ({
  children,
  title,
  maxWidthClassName = 'max-w-3xl',
  mxClassName = 'mx-auto',
  pxClassName = 'px-4 md:px-8',
}) => (
  <Layout>
    <article className={`${maxWidthClassName} ${mxClassName} ${pxClassName}`}>
      {title && <h1>{title}</h1>}
      {children}
    </article>
  </Layout>
);

export default SingleColPageLayout;
