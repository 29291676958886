import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img src="/img/client-logos/autotrader.png" alt="autoTRADER.ca" className="client-logo" />
    <h3>{`The Client`}</h3>
    <p>{`autoTRADER.ca is the largest automotive sales website in Canada with over 2 million monthly page views.`}</p>
    <h3>{`The Problem`}</h3>
    <p>{`The autoTRADER.ca mobile website was a poor relation to their desktop website and native mobile apps. The user experience was poor, it had significantly less features and was built on outdated technologies. Maintaining the site was become a major overhead.`}</p>
    <h3>{`The Solution`}</h3>
    <p>{`Whilst building a fully responsive single site to cover desktop and mobile devices was a long-term business strategy, the immediate need to improve their mobile web presence due to the aforementioned pains caused the client to bring me on as the lead developer for the rebuild of their mobile optimised website.`}</p>
    <p>{`I worked with an in-house design consultant and led a team of 3 developers to deliver a significantly improved UX and load-time performance, along with a new consumer reviews feature. A new technology stack was introduced to enable better cross-browser/device support and codebase maintainability going forward.`}</p>
    <div className="testimonial">
    <div className="testimonial-body">
        <p>
            Paul is one of the few developers that are able to make a BIG impact in a short period of time, single handedly worked on re-developing our mobile version of autoTRADER.ca which launched in record time as a fantastic success. Paul is a great team player, quiet but great attitude and commitment, speed and quality to match. Very professional and skilled on the latest technologies - I have no doubt he will make an impactful addition to any dev team.
        </p>
    </div>
    <div className="testimonial-footer">
        <span className="person-name">Allen Wales</span>,&nbsp;
        <span className="person-role">Vice-President Technology at autoTRADER.ca</span>
    </div>
    </div>
    <div className="testimonial">
    <div className="testimonial-body">
        <p>
            One of Paul's major achievements here at autoTRADER.ca was our mobile web site redesign project. It was under a risk of not being completed on time and budget, but Paul literally saved the day even though he had to rebuild it from ground up. I was really impressed by his professionalism and work ethic, because the project was completed in a record breaking time with a minimal amount of defects reported by our QA department.
        </p>
    </div>
    <div className="testimonial-footer">
        <span className="person-name">Alexei Chudinov</span>
        <span className="person-role">Senior Development Manager at autoTRADER.ca</span>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      