// helper function from https://stackoverflow.com/questions/29855098/is-there-a-built-in-javascript-function-similar-to-os-path-join
const pathJoin = (pathParts, ensureTrailingSlash = false) => {
	let output = pathParts
		.map((part, i) => {
			if (i === 0) {
				return part.trim().replace(/[\/]*$/g, '');
			}
			return part.trim().replace(/(^[\/]*|[\/]*$)/g, '');

		})
		.filter((x) => x.length)
		.join('/');
	if (ensureTrailingSlash && output.substr(-1) !== '/') {
		output += '/';
	}
	return output;
};

export default pathJoin;
