import React from 'react';
import Helmet from 'react-helmet';
import Nav from '../components/Nav';
import SiteFooter from '../components/SiteFooter';
import './main.css';
import 'typeface-merriweather';
import 'typeface-lato';

import favicon16 from '../images/logo/favicon-16x16.png';
import favicon32 from '../images/logo/favicon-32x32.png';
import shortcutIcon from '../images/logo/apple-touch-icon.png';

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="flex min-h-screen flex-col">
        <Helmet
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '16x16',
              href: `${favicon16}`,
            },
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '32x32',
              href: `${favicon32}`,
            },
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${shortcutIcon}`,
            },
          ]}
        >
          <html lang="en" />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <body className="leading-8 bg-gray-800" />
        </Helmet>
        <Nav />
        <main className="flex-grow bg-white lg:pt-8 pb-16">{children}</main>
        <SiteFooter />
      </div>
    );
  }
}
