import React from 'react';
import SvgLogo from '../images/logo/serverlessfirst-logo-full.svg';

const Logo = ({ width = 56 }) => {
    return (
      <span className="sf-logo inline-block pt-2">
        <img src={SvgLogo} alt="Serverless First" className={`w-${width}`} />
      </span>
    );
};

export default Logo;
