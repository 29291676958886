import React, { useState } from 'react';
import { Link } from 'gatsby';
import Logo from './Logo';

const WORKSHOP_LINK_LABEL = 'Testing Workshop';

const linksData = [
  {
    label: 'Articles',
    path: '/articles/',
  },
  {
    label: 'Newsletter',
    path: '/newsletter/',
  },
  // {
  //   label: 'Clients',
  //   path: '/case-studies/',
  // },
  {
    label: 'About',
    path: '/about/',
  },
  // {
  //   label: 'Testing Book',
  //   path: 'https://serverlesstestinghandbook.com',
  //   external: true,
  // },
  {
    label: 'Notes',
    path: 'https://notes.paulswail.com',
    external: true,
  },
  {
    label: 'Hire Me',
    path: '/services/',
  },
];

const Nav = () => {
  const [isExpanded, toggleExpansion] = useState(false);
  return (
    <nav
      className="top-nav font-sans border-b border-gray-200 bg-white"
      role="navigation"
    >
      <div className="flex items-center justify-between flex-wrap max-w-screen-xl mx-auto">
        <div className="flex items-center flex-shrink-0 text-white pl-4 md:pl-8 py-2 mr-6 my-4 md:my-6">
          <Link to="/" rel="home" className="border-none">
            <Logo />
          </Link>
        </div>
        <div className="block lg:hidden px-4 py-2">
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            type="button"
            className="flex items-center ml-3 py-2 focus:outline-none"
          >
            <svg className="h-8 w-8 fill-current" viewBox="0 0 24 24">
              <title>Menu</title>
              <path
                className={`${isExpanded ? 'block' : 'hidden'}`}
                fillRule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
              />
              <path
                className={`${isExpanded ? 'hidden' : 'block'}`}
                fillRule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </button>
        </div>
        <div
          className={`${
            isExpanded ? 'block' : 'hidden'
          } w-full block flex-grow lg:flex lg:items-center lg:w-auto bg-gray-800 lg:bg-white text-white lg:text-gray-900`}
        >
          <ul className="nav-menu list-reset lg:flex justify-center flex-1 items-center px-4 lg:px-0 lg:ml-8 border-t border-gray-200 lg:border-none">
            {linksData.map((l) => (
              <li
                key={l.path}
                className="mb-0 px-4 py-1 border-b border-gray-700 lg:border-none"
              >
                {l.external && (
                  <a
                    className="block lg:inline-block py-1 lg:uppercase font-semibold border-none tracking-wider text-base"
                    href={l.path}
                  >
                    {l.label}
                  </a>
                )}
                {!l.external && (
                  <Link
                    className="block lg:inline-block py-1 lg:uppercase font-semibold border-none tracking-wider text-base"
                    to={l.path}
                    activeClassName="active"
                  >
                    {l.label}
                  </Link>
                )}
              </li>
            ))}
            {/* <li className="lg:hidden mb-0 px-4 py-1 border-b border-gray-700 lg:border-none">
              <Link className="block lg:inline-block py-1 lg:uppercase font-semibold border-none tracking-wider text-base" to="/workshops/testing/" activeClassName="active">
                {WORKSHOP_LINK_LABEL}
              </Link>
            </li> */}
          </ul>
        </div>
        {/* <div className="hidden lg:flex lg:justify-end lg:flex-1 lg:items-center px-4 lg:px-0 lg:mr-8">
          <Link className="inline-block py-1 font-semibold tracking-wider whitespace-no-wrap text-base mb-0 px-4 py-2 bg-primary-600 border-2 border-primary-200 hover:border-primary-400 rounded-md" to="/workshops/testing/">
            {WORKSHOP_LINK_LABEL}
          </Link>
        </div> */}
      </div>
    </nav>
  );
};

export default Nav;
